//#region > Imports
//> React
// Contains all the functionality necessary to define React components
import { Component } from "react";
import { MDBContainer } from "mdbreact";

//> Components

//#endregion

//#region > Components
class App extends Component {
  render() {
    return (
      <MDBContainer className="flex-center text-center">
        <div>
          <p className="h2">
            <span className="font-weight-bold">Kanbon.</span>{" "}
            <span className="text-muted">
              <span className="blue-text">|</span> Investors
            </span>
          </p>

          <div className="mt-5">
            <p className="lead">
              Welcome to our ever growing family of partners and investors.
            </p>
          </div>
          <div className="mt-5">
            <p>Want more information?</p>
            <p>
              <a href="mailto:investors@kanbon.io">investors@kanbon.io</a>
            </p>
          </div>
        </div>
      </MDBContainer>
    );
  }
}
//#endregion

//#region > Exports
export default App;
//#endregion

/**
 * SPDX-License-Identifier: (EUPL-1.2)
 * Copyright © 2021 Christian Aichner
 */
